import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import AlbumGrid, { AlbumInterface } from '../components/AlbumGrid/albumGrid';
import Layout from '../components/layout';
import SEO from '../components/SEO/seo';
import { Albums } from '../data/albumData';

const BuildingStuffPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );

  return (
    <Layout noheader nofooter >
         <SEO lang="en" description="A curated playlist of videos about building something. Tribute to human labor.Best albums of all time according to Artyom Alekseev" title={'Building Stuff'} keywords={['designer', 'artyom', 'alekseev', 'Artyom Alekseev', 'ux', 'ui']}>
        </SEO>
        <div style={{width: "100%", height: "100vh", position: "absolute", top: 0, zIndex: -1}}>
        <iframe style={{width: "100%", height: "100%"}} src="https://www.youtube.com/embed/videoseries?controls=0&amp;list=PLGA9ju2bbuZ2YufT879ObYlnUuk6qmpL_" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    </Layout>

  ); };

export default BuildingStuffPage;
